import React from 'react';

function Logo() {
  return (
    <div className="text-6xl font-bold text-darkBlue">
      QuibTech Media
    </div>
  );
}

export default Logo;
